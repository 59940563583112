.custom-shape-divider-bottom-1642574667 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1642574667 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 129px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1642574667 .shape-fill {
  fill: #ffffff;
}

.hero-container-cc {
  position: relative;
  background-color: #1e7cd9;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

@media screen and (max-width: 960px) {
    .hero-container-cc {
        height: max-content;
        width: 100vw;
      }
}