@import url(https://fonts.googleapis.com/css2?family=Audiowide&display=swap);
/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition-delay: 100ms;
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    -webkit-transform: scale(1);
            transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.table-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.head {
  font-size: 3rem;
  font-weight: bolder;
  color: rgb(75, 0, 195);
  display: flex;
  justify-content: center;
  align-content: center;
}
.subheading {
  font-size: 1.5rem;
  font-weight: bolder;
  color: rgb(135, 69, 241);
  display: flex;
  justify-content: center;
  align-content: center;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: 2%;
}

.styled-table thead tr {
  background-color: rgb(75, 0, 195);
  color: #ffffff;
  text-align: center;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
/* 
.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
} */

td {
  font-weight: bold;
  color: #1713ff;
  text-align: center;
}

@media screen and (max-width : 850px) {
    .table-head {
        flex-direction: column;
      }
}
.dropdown-menu {
  background-image: url(/static/media/drp-bg-1.a9b22815.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 70vw;
  height: 50vh;
  z-index: 1;
  position: absolute;
  left: 20%;
  list-style: none;
  box-shadow: -1px 5px 21px 4px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: -1px 5px 21px 4px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: -1px 5px 21px 4px rgba(0, 0, 0, 0.54);
  /* text-align: start; */
}
.dropdown-flex {
  height: 100%;
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-items: stretch;
  /* border: black 1px groove; */
}
.dropdown-header {
  /* background: #030097; */
  cursor: pointer;
  border-bottom: dashed rgb(199, 23, 23) 1px;
  border-radius: 5vw;
}

.dropdown-header:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  flex: 2 1;
  width: 23vw;
  text-align: center;
  align-content: center;
  justify-content: content;
  /* height: 100%; */
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  display: flex;
  flex: 4 1;
  border-right: black 1px solid;
  padding-left: 10vh;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}
.navbar-logo > img{
  height: 20vh;
  width: 20vh;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  /* button {
    display: none;
  } */
}
.blur-bg {
  /* background-color: rgb(71, 61, 61); */
  width: -webkit-max-content;
  width: max-content;
  padding: 2vh;
  margin: 1vh;
  background-color: rgba(0, 0, 0, 0.527);
  text-align: center;
}
.hero-blur-subheading {
  color: white;
  font-weight: bold;
  font-size: 2.5vh;
  text-align: center;
}
.hero-blur-subheading1 {
  color: royalblue;
  font-size: 3vh;
  font-weight: bolder;
}

.btn-shadow {
  box-shadow: -3px 10px 17px -6px rgba(255,255,255,0.74);
-webkit-box-shadow: -3px 10px 17px -6px rgba(255,255,255,0.74);
-moz-box-shadow: -3px 10px 17px -6px rgba(255,255,255,0.74);
}

@media screen and (max-width: 960px) {
  .blur-bg {
    padding: 0.3vh;
    margin: 0.1vh;

  }
  .hero-blur-subheading {
  color: white;
  font-weight: bold;
  font-size: 1.3vh;
  text-align: center;
}
.hero-blur-subheading1 {
  color: royalblue;
  font-size: 1.5vh;
  font-weight: bolder;
}
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
.outerbox {
  height: 100vh;
  background: url(/static/media/LandingBackground.85ea1e7c.jpg);
}
.innerbox {
  margin: 0 2rem;
}

.slanted {
  color: #0000fe;
  margin: 0;
  background: white;
  
  -webkit-transform: skew(-10deg);
  
          transform: skew(-10deg);
}
.slanted ::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #000;
    -webkit-transform: skew(-30deg);
            transform: skew(-30deg);
    -webkit-transform-origin:top;
            transform-origin:top;
}

.tic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  font-weight: bolder;
  color: rgb(75, 0, 195);
}

.tiw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ti1 {
  justify-self: center;
  height: 450px;
  width: 100%;
}
.ti2 {
  justify-self: center;
  height: 450px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .tiw {
    flex-direction: column;
    padding-left: 0px;
  }
  .ti1 {
    justify-self: center;
    height: auto;
    width: 100%;
  }
  .ti2 {
    justify-self: center;
    height: auto;
    width: 100%;
  }
}

.custom-shape-divider-bottom-1642574667 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.custom-shape-divider-bottom-1642574667 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 129px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1642574667 .shape-fill {
  fill: #ffffff;
}

.hero-container-cc {
  position: relative;
  background-color: #1e7cd9;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

@media screen and (max-width: 960px) {
    .hero-container-cc {
        height: -webkit-max-content;
        height: max-content;
        width: 100vw;
      }
}
.spg-container {
  position: relative;
  background-color: #2a2a72;
  /* background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%); */
  /* background-image: linear-gradient(to right, #8360c3, #2ebf91); */
  /* background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e); */
  /* background-image: linear-gradient( 109.6deg, rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% ); */
  /* background-image: linear-gradient(to right bottom, #073d8f, #0074c5, #00a5bd, #00ce7d, #a8eb12); */
  /* background-image: linear-gradient(to right bottom, #99ff50, #00f99f, #00eade, #00d5ff, #00bcff, #00a9ff, #0094ff, #277bff, #0068ff, #0053ff, #0039ff, #1f00ff); */
  /* background-image: linear-gradient(
    to right bottom,
    #7067d3,
    #6865db,
    #5e63e3,
    #5161ec,
    #3f5ff4,
    #335af8,
    #2254fb,
    #004fff,
    #0043ff,
    #0036ff,
    #0e24ff,
    #1f00ff
  ); */
}
.sfs-main {
  background-image: linear-gradient(
    to right bottom,
    #073d8f,
    #0074c5,
    #00a5bd,
    #00ce7d,
    #a8eb12
  );
}
.sap-main {
  /* background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%); */
  /* background-image: linear-gradient(to right, #8360c3, #2ebf91); */
  /* background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e); */
  /* background-image: linear-gradient( 109.6deg, rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% ); */
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.spg-inner {
  position: relative;
  height: 130vh;
  width: 100vw;
  overflow: hidden;
  z-index: 20;
}

.spg-inner-flex {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 130vh;
  justify-content: space-evenly;
}
.spg-head {
  color: white;
  font: white bolder;
  font-size: 4rem;
  margin: 0vh;
}
.spg-img {
  max-width: 30vw;
  width: auto;
  height: auto;
  -webkit-filter: drop-shadow(8px 8px 10px #222);
  filter: drop-shadow(8px 8px 10px #222);
  /* padding-top: 50%; */
  /* width: ; */
}
.spg-img-sap {
  max-width: 20vw;
  width: auto;
  height: auto;
  -webkit-filter: drop-shadow(8px 8px 10px #222);
  filter: drop-shadow(8px 8px 10px #222);
  /* padding-top: 50%; */
  /* width: ; */
}
.sap-main {
  height: 140vh;
}
@media screen and (max-width: 960px) {
  .spg-inner {
    /* position: relative; */
    height: 40vh;
  }

  .spg-inner-flex {
    height: 40vh;
  }
  .sap-main {
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  .sap-main, .sap-main > .spg-inner, .sap-main > .spg-inner > div {
    height: -webkit-max-content;
    height: max-content;
  }
}

.warm-filter {
  background: linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
  position: relative;
  min-width: 15vw;
  max-width: 25vw;
  max-height: 30vh;
  overflow: hidden;
  width: 24%;
  cursor: pointer;
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 3vw;
          margin-inline-start: 3vw;
  -webkit-margin-end: 5vw;
          margin-inline-end: 5vw;
  flex: 1 0 21%; 
  border-radius: 1vw;
  box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  /* margin: 5px;
  height: 100px;
  background-color: blue; */
}

.figcaption {
    /* padding: 0.5em; */
  color: rgb(255, 255, 255);
  /* text-transform: uppercase;  */
  font-size: 2vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.warm-filter img {
  opacity: 0.9;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);

}
.img-sap-bg {
  background: no-repeat center contain !important ;
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
}

.warm-filter:hover img {
  opacity: 0.4;
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
}

.warm-filter .figcaption {
  padding: 1em;
  /* background-color: rgba(58, 52, 42, 0.7); */
  transition: background-color 0.35s;
}

.warm-filter:hover .figcaption {
  background-color: rgba(58, 52, 42, 0);
}
.card-header-2 { 
    text-align: center;
    font-size: small;
    padding-top: 1vh;
}
.warm-filter .card-header-1 {
  /* margin: 20% 0 2vh 0; */
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  display: block;
  font-size: 2.3vw;
  /* margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
  font-weight: bold;
  text-align: center;
}

.warm-filter:hover .card-header-1 {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.warm-filter .figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  left: 30px;
  bottom: 30px;
  border: 1px solid;
  content: "";
}

.warm-filter .card-header-2,
.warm-filter .figcaption::before {
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.warm-filter:hover .card-header-2,
.warm-filter:hover .figcaption::before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.warm-filter-img:hover {
  
content: url("https://images.unsplash.com/photo-1579403124614-197f69d8187b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80");
}
.contact-us-container {
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 80vh;
  justify-content: center;
  margin: 1.5rem;
  margin-top: 3.5rem;
  margin-bottom: 4rem;
  border: gray 1px solid;
  border-radius: 25px;
  background-color: rgb(243, 238, 231);
  box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  /* border: red 2px solid; */
}

.contact-us-left {
  /* border: 2px red solid; */
  /* flex: 1; */
  background-image: url("https://img.freepik.com/free-vector/elegant-white-background-with-shiny-lines_1017-17580.jpg?size=626&ext=jpg");
  /* border: rgb(129, 51, 255) 2px solid; */
  width: 25vw;
  /* display: flex; */
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 1rem;
  background-color: rgb(221, 221, 221);
}
.contact-us-right {
  /* flex: 2; */
  /* border: rgb(0, 0, 0) 2px solid; */
  width: 55vw;
  /* background-color: rgb(1, 0, 3); */
}

.loc-add {
  height: 20vh;
}

/* .social-links {
  align-self: flex-end;
} */
@media screen and (max-width: 960px) {
  .contact-us-container {
    flex-direction: column;
    height: -webkit-max-content;
    height: max-content;
    align-items: center;
    justify-content: center;
  }
  .contact-us-left {
    /* border: 2px red solid; */
    /* flex: 1; */
    background-image: url("https://img.freepik.com/free-vector/elegant-white-background-with-shiny-lines_1017-17580.jpg?size=626&ext=jpg");
    /* border: rgb(129, 51, 255) 2px solid; */
    width: 100%;
    /* display: flex; */
    border-top-left-radius: 25px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 25px;
    padding: 1rem;
    background-color: rgb(221, 221, 221);
  }
  .contact-us-right {
    /* flex: 2; */
    /* border: rgb(0, 0, 0) 2px solid; */
    width: 100%;
    padding-bottom: 10vh;
    /* background-color: rgb(1, 0, 3); */
  }
  
}

.form-container > Input{
    padding-left: 10px;
    border-radius: 2px;
}
.form-container > TextArea{
    padding-left: 10px;

}

.hero {
  height: 100vh;
  background: url(/static/media/hero-image.a157b621.jpg);
  background-position: center;
  padding:  1.5rem 4rem 2.5rem 4rem;
}
.home-qcs {
  min-height: 110vh;
  padding-top: 5rem;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.subheading_home{
    /* color : red; */
    font-size: 1.5rem;
}
.heading_home{
    color: rgb(51, 45, 105);
    font-size: 3.6rem;
}
.slanted_home {
  position: absolute;
  width: 100%;
  padding: 0.5rem 1rem;
  height: 5.5rem;
  width: 50rem;
  background: white;
  -webkit-transform: skew(-12deg);
  transform: skew(-12deg); 
}
.h_home{
  position: absolute;
  color: #0000fe;
  width: -webkit-max-content;
  width: max-content;
  padding: 0.5rem 1rem;
  text-shadow: 2px 2px 2px #c9b8b8;
}
.sh_home{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight:bolder;
  text-align: left;
  text-shadow: 4px 4px 4px #000000;
}
@media screen and (max-width: 600px) {
  .hero {
    padding: 1rem 1rem;
  }
}
/* 
  border-bottom: 2px solid red;
  border-right: 2px solid red;
  -moz-transform: skew(-45deg);
  -webkit-transform: skew(-45deg);
  transform: skew(-45deg); */
.band{width:90%;max-width:1240px;margin:0 auto;padding-bottom:2em;display:grid;grid-template-columns:1fr;grid-template-rows:auto;grid-gap:20px}@media (min-width: 30em){.band{grid-template-columns:1fr 1fr}}@media (min-width: 60em){.band{grid-template-columns:repeat(4, 1fr)}}.card{background:white;text-decoration:none;color:#444;box-shadow:0 2px 5px rgba(0,0,0,0.1);display:flex;flex-direction:column;min-height:100%;position:relative;top:0;transition:all .1s ease-in}.card:hover{top:-2px;box-shadow:0 4px 5px rgba(0,0,0,0.2)}.card article{padding:20px;flex:1 1;display:flex;flex-direction:column;justify-content:space-between}.card h1{font-size:20px;margin:0;color:#333}.card p{flex:1 1;line-height:1.4}.card span{font-size:12px;font-weight:bold;color:#999;text-transform:uppercase;letter-spacing:.05em;margin:2em  0 0 0}.card .date{font-size:12px;font-weight:bold;color:#000;text-transform:uppercase;letter-spacing:.05em;margin:0.1em  0 0 0}.card .tags{font-size:12px;font-weight:bold;color:red;text-transform:uppercase;letter-spacing:.05em;margin:0.1em  0 0 0}.card .thumb{padding-bottom:60%;background-size:cover;background-position:center center}@media (min-width: 60em){.item-1{grid-column:1 / span 2}.item-1 h1{font-size:24px}}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.form-container > p{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    align-content: center;
}
.form-container > p > label {
    margin: 0.5rem 1rem;
    padding: 0.3rem;
    color: red;
    font-weight: bolder;
}
.int_field {
    border: 1px solid gray;
    margin: 0.5rem 1rem;
    padding: 0.3rem;
    border-radius: 0.2rem;
}
.int_field:focus {
    background: rgb(250, 243, 234);
}
.form-btn {
    margin: 0.5rem 15rem;
    padding: 0.5rem;
    border-radius: 1rem;
    color: rgb(255, 255, 254);
    background-color: rgb(25, 59, 250);
    transition: 0.4s ;
}
.form-btn:hover{
    padding: 0.6rem;
    font-size: 1.2rem;
    margin: 0.5rem 14rem;
}

 .modal-container::-webkit-scrollbar {
     width: 5px;
 }
 
 .modal-container::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
     border-radius: 10px;
 }
 
 /* .modal-container::-webkit-scrollbar-thumb {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
 } */
.blog-head {
  display: flex;
  justify-content: center;
  align-content: center;
}
.blog-container {
  padding: 2rem 0;
  width: 60vw;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Neue Radial B Medium';
}
.blog-container > img {
  width: 100%;
}
.bold {
  font-weight: bold;
}
.italic {
  font: italic;
}
.bg-subheading {
  font-weight: bold;
  font-size: 1.6rem;
}
strong {
  font-weight: 600;

}
.blog-title {
  font-size: 2.2rem;
  font-weight: bolder;
  color: black;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 0.5rem 1rem;
}
.blog-subheading {
  font-style: italic;
  /* font-weight: bold; */
  text-align: center;
}
.blog-details {
  /* display: flex;
  justify-content: space-between; */
  font-size: 0.9rem;
  margin: 1em;
}
/* .blog-tag {
  color: rgb(40, 79, 255);
  justify-content: left;
  /* text-align: center; */
  /* font-weight: bold; */
/* } */ */
.blog-author {
  justify-content: center;
  /* text-align: center; */
}
.blog-date {
  color: gray;
  justify-content: right;
  /* text-align: center; */
  font-weight: bold;
}
.nd-para > img {
  width: 100%;
  height: 100%;
}

