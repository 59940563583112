.tic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  font-weight: bolder;
  color: rgb(75, 0, 195);
}

.tiw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.ti1 {
  justify-self: center;
  height: 450px;
  width: 100%;
}
.ti2 {
  justify-self: center;
  height: 450px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .tiw {
    flex-direction: column;
    padding-left: 0px;
  }
  .ti1 {
    justify-self: center;
    height: auto;
    width: 100%;
  }
  .ti2 {
    justify-self: center;
    height: auto;
    width: 100%;
  }
}
