.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.form-container > p{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    align-content: center;
}
.form-container > p > label {
    margin: 0.5rem 1rem;
    padding: 0.3rem;
    color: red;
    font-weight: bolder;
}
.int_field {
    border: 1px solid gray;
    margin: 0.5rem 1rem;
    padding: 0.3rem;
    border-radius: 0.2rem;
}
.int_field:focus {
    background: rgb(250, 243, 234);
}
.form-btn {
    margin: 0.5rem 15rem;
    padding: 0.5rem;
    border-radius: 1rem;
    color: rgb(255, 255, 254);
    background-color: rgb(25, 59, 250);
    transition: 0.4s ;
}
.form-btn:hover{
    padding: 0.6rem;
    font-size: 1.2rem;
    margin: 0.5rem 14rem;
}