.blog-head {
  display: flex;
  justify-content: center;
  align-content: center;
}
.blog-container {
  padding: 2rem 0;
  width: 60vw;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Neue Radial B Medium';
}
.blog-container > img {
  width: 100%;
}
.bold {
  font-weight: bold;
}
.italic {
  font: italic;
}
.bg-subheading {
  font-weight: bold;
  font-size: 1.6rem;
}
strong {
  font-weight: 600;

}
.blog-title {
  font-size: 2.2rem;
  font-weight: bolder;
  color: black;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 0.5rem 1rem;
}
.blog-subheading {
  font-style: italic;
  /* font-weight: bold; */
  text-align: center;
}
.blog-details {
  /* display: flex;
  justify-content: space-between; */
  font-size: 0.9rem;
  margin: 1em;
}
/* .blog-tag {
  color: rgb(40, 79, 255);
  justify-content: left;
  /* text-align: center; */
  /* font-weight: bold; */
/* } */ */
.blog-author {
  justify-content: center;
  /* text-align: center; */
}
.blog-date {
  color: gray;
  justify-content: right;
  /* text-align: center; */
  font-weight: bold;
}
.nd-para > img {
  width: 100%;
  height: 100%;
}
