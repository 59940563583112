.blur-bg {
  /* background-color: rgb(71, 61, 61); */
  width: max-content;
  padding: 2vh;
  margin: 1vh;
  background-color: rgba(0, 0, 0, 0.527);
  text-align: center;
}
.hero-blur-subheading {
  color: white;
  font-weight: bold;
  font-size: 2.5vh;
  text-align: center;
}
.hero-blur-subheading1 {
  color: royalblue;
  font-size: 3vh;
  font-weight: bolder;
}

.btn-shadow {
  box-shadow: -3px 10px 17px -6px rgba(255,255,255,0.74);
-webkit-box-shadow: -3px 10px 17px -6px rgba(255,255,255,0.74);
-moz-box-shadow: -3px 10px 17px -6px rgba(255,255,255,0.74);
}

@media screen and (max-width: 960px) {
  .blur-bg {
    padding: 0.3vh;
    margin: 0.1vh;

  }
  .hero-blur-subheading {
  color: white;
  font-weight: bold;
  font-size: 1.3vh;
  text-align: center;
}
.hero-blur-subheading1 {
  color: royalblue;
  font-size: 1.5vh;
  font-weight: bolder;
}
}