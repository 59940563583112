.table-head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.head {
  font-size: 3rem;
  font-weight: bolder;
  color: rgb(75, 0, 195);
  display: flex;
  justify-content: center;
  align-content: center;
}
.subheading {
  font-size: 1.5rem;
  font-weight: bolder;
  color: rgb(135, 69, 241);
  display: flex;
  justify-content: center;
  align-content: center;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: 2%;
}

.styled-table thead tr {
  background-color: rgb(75, 0, 195);
  color: #ffffff;
  text-align: center;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
/* 
.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
} */

td {
  font-weight: bold;
  color: #1713ff;
  text-align: center;
}

@media screen and (max-width : 850px) {
    .table-head {
        flex-direction: column;
      }
}