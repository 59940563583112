.warm-filter {
  background: linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%);
  position: relative;
  min-width: 15vw;
  max-width: 25vw;
  max-height: 30vh;
  overflow: hidden;
  width: 24%;
  cursor: pointer;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 3vw;
  margin-inline-end: 5vw;
  flex: 1 0 21%; 
  border-radius: 1vw;
  box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 4px 32px 0px rgba(0, 0, 0, 0.75);
  /* margin: 5px;
  height: 100px;
  background-color: blue; */
}

.figcaption {
    /* padding: 0.5em; */
  color: rgb(255, 255, 255);
  /* text-transform: uppercase;  */
  font-size: 2vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.warm-filter img {
  opacity: 0.9;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(1, 1);

}
.img-sap-bg {
  background: no-repeat center contain !important ;
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
}

.warm-filter:hover img {
  opacity: 0.4;
  transform: scale(1.2, 1.2);
}

.warm-filter .figcaption {
  padding: 1em;
  /* background-color: rgba(58, 52, 42, 0.7); */
  transition: background-color 0.35s;
}

.warm-filter:hover .figcaption {
  background-color: rgba(58, 52, 42, 0);
}
.card-header-2 { 
    text-align: center;
    font-size: small;
    padding-top: 1vh;
}
.warm-filter .card-header-1 {
  /* margin: 20% 0 2vh 0; */
  transition: transform 0.35s;
  transform: translateY(100%);
  display: block;
  font-size: 2.3vw;
  /* margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
  font-weight: bold;
  text-align: center;
}

.warm-filter:hover .card-header-1 {
  transform: translateY(0);
}

.warm-filter .figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  left: 30px;
  bottom: 30px;
  border: 1px solid;
  content: "";
}

.warm-filter .card-header-2,
.warm-filter .figcaption::before {
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(0);
}

.warm-filter:hover .card-header-2,
.warm-filter:hover .figcaption::before {
  opacity: 1;
  transform: scale(1);
}

.warm-filter-img:hover {
  
content: url("https://images.unsplash.com/photo-1579403124614-197f69d8187b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80");
}